
import { defineComponent, onMounted, ref} from "vue";
import FullCalendar from "@fullcalendar/vue3";
import AuthService from "@/services/AuthService";
import Calendar1 from "@/views/agenda-virtual/components/multiexibicao/Calendar1.vue";
import Calendar2 from "@/views/agenda-virtual/components/multiexibicao/Calendar2.vue";
import useEmitter from '@/composables/Emmiter';
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "calendar-app-1",
  components: {
    FullCalendar,
    Calendar1,
    Calendar2
  },

  setup() {
    const userInfo = AuthService.getUsuarioAuth();
    const codNivel = userInfo.codNivel;
    const router = useRouter();
    const store = useStore();
    const emitter = useEmitter();
    const cnpjConcessionaria = ref("");
    const codConcessionaria = ref("");

    emitter.on("update-calendar", () => {
      const concessionariaInfo = store.getters.concessionariaSelectedInfo;
      
      cnpjConcessionaria.value = concessionariaInfo.cnpj;
      codConcessionaria.value = concessionariaInfo.codConcessionaria;
    });

    function isScreenLockSupported() {
      return ('wakeLock' in navigator);
    }

    onMounted(() => {
      try{
        if(isScreenLockSupported()){
          const anyNavigator:any = navigator;
          anyNavigator.wakeLock.request('screen');
        }
      } catch (e){
        console.error(e);
      }

      if(store.getters.concessionariaSelectedInfo){
        const concessionariaInfo = store.getters.concessionariaSelectedInfo;
        cnpjConcessionaria.value = concessionariaInfo.cnpj;
        codConcessionaria.value = concessionariaInfo.codConcessionaria;
      }
    });

    function calendarFullscreen() {
      if(document.fullscreenElement){
        document.exitFullscreen();
        return
      }
      let elem:any = document.querySelector("#iframe-container");
      if (elem.requestFullscreen) {
          elem.requestFullscreen({ navigationUI: "show" });
      } else if (elem.mozRequestFullScreen) { /* Firefox */
          elem.mozRequestFullScreen({ navigationUI: "show" });
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
          elem.webkitRequestFullscreen({ navigationUI: "show" });
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
          elem.msRequestFullscreen({ navigationUI: "show" });
      }
    };

    function goTo(url){
			router.push(url)
		};

    return {
      cnpjConcessionaria,
      codConcessionaria,
      codNivel,
      calendarFullscreen,
      goTo
    };
  },
});
